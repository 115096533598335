<template>
  <v-card class="mb-5">
    <v-row
      v-for="(title, index) in titles"
      :key="index"
      class="ma-0"
    >
      <v-col
        cols="12"
        md="12"
        class="pa-0"
      >
        <v-divider></v-divider>
      </v-col>

      <v-col
        cols="6"
        md="6"
        class="d-flex justify-space-between align-center fz-14 py-3 px-4"
      >
        <div v-if="title.label" class="mr-3">{{ title.label }}</div>
      </v-col>

      <v-col
        cols="3"
        md="3"
        class="d-flex align-center fz-16 py-4"
        style="color: #637381;"
      >
        <div v-if="!title.label" class="font-weight-semi-bold">Total</div>
      </v-col>

      <v-col
        cols="3"
        md="3"
        class="d-flex align-center py-3 px-4 fz-14"
        style="color: #637381;"
      >
        <div class="font-weight-semi-bold">{{ title.prop === 'Total' ? 'Total' : '$' + toFixed(invoices[title.prop]) }}</div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'invoice-table',
  props: {
    invoices: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      titles: [
        {
          label: 'Title',
          prop: 'Total'
        },
        {
          label: 'Inbound Sms Cost',
          prop: 'totalInboundSmsCost'
        },
        {
          label: 'Outbound Sms Cost',
          prop: 'totalOutboundSmsCost'
        },
        {
          label: 'Inbound Call Cost',
          prop: 'totalInboundCallCost'
        },
        {
          label: 'Outbound Call Cost',
          prop: 'totalOutboundCallCost'
        },
        {
          label: 'MRC Cost',
          prop: 'totalMrcCost'
        },
        {
          label: 'NRC Cost',
          prop: 'totalNrcCost'
        },
        {
          label: '',
          prop: 'amount'
        }
      ]
    }
  },
  methods: {
    toFixed: function (quantity) {
      return quantity ? quantity.toFixed(2) : '0.00'
    }
  }
}
</script>

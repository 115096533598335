<template>
  <div class="py-4">
    <router-link
      to="/settings"
      class="back-link px-3 px-md-0"
    >
      <v-icon size="24px">
        navigate_before
      </v-icon>
      Settings
    </router-link>

    <h1 class="dark-text font-weight-semi-bold fz-28 mb-4 px-4 px-md-0">Payments</h1>

    <v-card
      :class="{ 'card-desktop': isDesktop }"
      style="box-shadow: unset;"
      color="transparent"
      class="px-3"
    >
      <v-row>
        <!-- <v-col
          cols="12"
          md="12"
          class="px-0"
        >
          <v-divider></v-divider>
        </v-col>

        <v-col
          cols="12"
          md="3"
          class="px-0 mt-4"
        >
          <h2 class="fz-16 font-weight-semi-bold px-4 px-md-0">Payment methods</h2>
        </v-col>

        <v-col
          cols="12"
          md="9"
          class="px-0 d-flex justify-end align-start"
        >
          <v-card
            :class="{ 'methods-desktop': isDesktop }"
            class="py-5 px-2 px-md-5 overflow-hidden"
            max-width="514px"
            width="100%"
          >
            <v-row>
              <template v-for="(card, index) in cardList">
                <v-list
                  :key="index"
                  width="100%"
                  class="py-0"
                >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon
                          class="mr-2"
                          color="#212B36"
                        >
                          payment
                        </v-icon>

                        <span class="fz-14">{{ securityCard(card.card) }}</span>

                        <v-btn
                          class="ml-4 fz-12 py-1 px-2"
                          :class="card.primary ? 'success' : ''"
                          color="#B3BCF5"
                          small
                          rounded
                          @click="btnPrimary(index, card.primary)"
                        >
                          Primary
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action
                      class="pt-3 pb-2 text-right d-flex flex-row ma-0"
                    >
                      <v-btn
                        v-if="isDesktop"
                        to="/edit-payment"
                        class="grey-button"
                      >
                        Edit
                      </v-btn>
                      <v-tooltip v-else top content-class="v-tooltip--top">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="grey-button pa-1 ml-2"
                            min-width="36px"
                            to="/edit-payment"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="#919EAB">edit</v-icon>
                          </v-btn>

                        </template>
                        <span>Edit</span>
                      </v-tooltip>

                      <v-tooltip top content-class="v-tooltip--top">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="grey-button pa-1 ml-2"
                            min-width="36px"
                            @click="removeCard(index)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="#919EAB">delete</v-icon>
                          </v-btn>

                        </template>
                        <span>Remove</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>

                  <v-col
                    cols="12"
                    md="12"
                    class="px-0"
                  >
                    <v-divider
                      class="hl-724"
                    ></v-divider>
                  </v-col>
                </v-list>
              </template>

              <v-col
                cols="12"
                mb="12"
              >
                <v-btn
                  class="violet-button"
                  to="/add-payment-method"
                >
                  Add payment method
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col> -->

        <!-- <v-col
          cols="12"
          md="12"
          class="px-0"
        >
          <v-divider></v-divider>
        </v-col> -->

        <v-col
          cols="12"
          md="12"
          class="px-0"
        >
          <v-card
            :loading="loading"
            :style="loading ? 'min-height:4px' : ''"
            class="overflow-hidden px-4"
          >
            <v-row>
              <template v-for="(item, index) in cardHistory">
                <v-list
                  :key="index"
                  width="100%"
                  class="py-4"
                >
                  <v-col
                    cols="12"
                    md="12"
                    class="pa-0"
                    v-if="index"
                  >
                    <v-divider
                      class="hl-724"
                    ></v-divider>
                  </v-col>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="fz-14 font-weight-bold"
                        style="color: #1D2428;"
                      >
                        {{ item.date }}
                      </v-list-item-title>

                      <v-list-item-subtitle>Invoice #{{ item.invoiceNumber }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-content>
                      <v-list-item-title class="fz-20">${{ item.amount }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action
                      class="pt-3 pb-2 text-right d-flex flex-row ma-0"
                    >
                      <v-tooltip top content-class="v-tooltip--top">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-if="$store.state.auth.currentUser.role === 'account_admin'"
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            @click.stop.prevent="$router.push({ name: 'Invoice', params: { uuid: item.invoiceUuid }})"
                          >
                            <v-icon size="20px" color="#919EAB">visibility</v-icon>
                          </v-btn>
                        </template>
                        <span>Open invoice</span>
                      </v-tooltip>

                      <v-tooltip top content-class="v-tooltip--top">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            @click="printHistory(index)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="#919EAB">print</v-icon>
                          </v-btn>
                        </template>
                        <span>Print invoice</span>
                      </v-tooltip>

                      <!-- <v-tooltip top content-class="v-tooltip--top">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="ml-2"
                            icon
                            @click="openDeleteDialog(index)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="#919EAB">delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete invoice</span>
                      </v-tooltip> -->
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </template>
            </v-row>

            <v-row v-if="!cardHistory.length && !loading">
              <v-col
                cols="12"
                md="12"
                class="text-center py-6"
              >
                <span class="dark-text fz-16">History empty!</span>
              </v-col>
            </v-row>
            <div v-if="total > take" class="d-flex justify-space-between">
              <div class="ma-5 br-3 grey-button d-flex">
                <template v-for="index in countPage">
                  <v-divider :key="index + 'v-divider--page'" v-if="index" vertical class="pa-0"/>
                  <v-btn
                    :class="{'grey-button--selected': index === page}"
                    text
                    tile
                    min-width="56"
                    class=""
                    :key="index"
                    @click="pagination(index)"
                  >{{ index }}
                  </v-btn>
                </template>
              </div>

              <div class="ma-5 br-3 grey-button d-flex">
                <v-btn
                  text
                  tile
                  class="px-0"
                  min-width="36"
                  width="36px"
                  @click="previousPage(page)"
                >
                  <v-icon small>
                    navigate_before
                  </v-icon>
                </v-btn>
                <v-divider vertical/>
                <v-btn
                  text
                  tile
                  min-width="36"
                  class="light-text px-0"
                  width="36px"
                  @click="nextPage(page)"
                >
                  <v-icon small>
                    navigate_next
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog
          persistent
          v-model="deleteDialog"
          :max-width="$vuetify.breakpoint.mdAndUp ? '350' : '260'"
        >
          <v-card>
            <v-card-title class="font-weight-bold">
              Delete payment
            </v-card-title>

            <v-card-text>
              Are you sure you want to delete payment?
            </v-card-text>

            <v-card-actions class="pb-5 pt-0 justify-md-end">

              <div :style="$vuetify.breakpoint.mdAndUp ? '' : 'width: 50%'"
                  class="mx-2">
                <v-btn
                  color="secondary"
                  outlined
                  class="w-100 text-capitalize"
                  @click="closeDeleteDialog"
                >
                  Cancel
                </v-btn>
              </div>

              <div :style="$vuetify.breakpoint.mdAndUp ? '' : 'width: 50%'"
                  class="mx-2">
                <v-btn
                  depressed
                  color="primary"
                  @click="removeHistory"
                  class="w-100 text-capitalize"
                >
                  Delete
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
      </v-dialog>
    </v-card>
    <invoice-table v-if="invoice" :invoices="invoice" id="invoice-table" />
  </div>
</template>

<script>
import InvoiceTable from '@/components/InvoiceTable'
import { mapActions } from 'vuex'
export default {
  name: 'Payments',
  components:
  {
    InvoiceTable
  },
  data: () => ({
    cardList: [
      /* {
        card: '4242424242424242',
        primary: false
      },
      {
        card: '5896547887455821',
        primary: false
      } */
    ],
    total: 0,
    page: 1,
    take: 10,
    cardHistory: [],
    deleteDialog: false,
    selectedPaymentUuid: null,
    invoice: null,
    loading: false
  }),
  created () {
    this.loadInvoices()
  },
  computed: {
    isDesktop () { return this.$vuetify.breakpoint.mdAndUp },
    countPage () {
      return Math.ceil(this.total / this.take)
    }
  },
  watch: {
    page () {
      this.loadInvoices()
    }
  },
  methods: {
    ...mapActions(['getInvoices']),
    async loadInvoices () {
      this.loading = true
      try {
        const { data } = await this.getInvoices({
          skip: (this.page - 1) * this.take,
          take: this.take
        })
        this.cardHistory = data.payload.data
        this.total = data.payload.count
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    btnPrimary (index, primary) {
      console.log(index)
    },
    removeCard (index) {
      this.cardList.splice(index, 1)
    },
    securityCard (card) {
      return card.replace(/^(.{4})([0-9]{8})/, '$1 **** **** ')
    },
    printHistory (index) {
      this.invoice = this.cardHistory[index]
      setTimeout(() => {
        this.$htmlToPaper('invoice-table')
        this.invoice = null
      }, 100)
    },
    removeHistory () {
      this.cardHistory.splice(this.selectedPaymentUuid, 1)
      this.deleteDialog = false
      this.selectedPaymentUuid = null
    },
    openDeleteDialog (uuid) {
      this.deleteDialog = true
      this.selectedPaymentUuid = uuid
    },
    closeDeleteDialog () {
      this.deleteDialog = false
      this.selectedPaymentUuid = null
    },
    pagination (index) {
      this.page = index
    },
    previousPage (page) {
      if (page > 1) {
        this.page = page - 1
      }
    },
    nextPage (page) {
      if (page < this.countPage) {
        this.page = page + 1
      }
    }
  }
}
</script>

<style scoped>
.methods-desktop {
  min-width: 430px;
}

.card-desktop {
  min-width: 565px;
}
</style>
